@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);

/* resets */

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}

/* generals */
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-end {
  justify-content: flex-end;
}
.close {
  display: none;
}
.active {
  background-color: #464e5bc0;
}

.wrapper-sidebar::-webkit-scrollbar-track {
  border-radius: 7px;
}
.wrapper-sidebar::-webkit-scrollbar {
  width: 7px;
}
.wrapper-sidebar::-webkit-scrollbar-thumb {
  border-radius: 7px;
}
.wrapper-sidebar::-webkit-scrollbar-thumb:hover {
  background: #1d365faf;
}

/* sidebar */

.wrapper-sidebar {
  display: flex;
  overflow-x: hidden;
  position: fixed;
  width: 0rem;
  transition: all ease 0.3s;
  z-index: 1;
}
.container-sidebar {
  height: 100vh;
}
.user-name {
  color: rgba(255, 255, 255, 0.863);
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  padding-top: 0.5rem;
}
.user-mail {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}
.wrapper-logo {
  position: relative;
}
.logo-sidebar {
  border: 0.6rem solid #252f3e;
  border-radius: 50%;
  height: 3.8rem;
  margin-top: 1.65rem;
}
.header-nav {
  background-color: #1b2330;
  width: 100%;
  height: 9.5rem;
  text-align: center;
}
.users-span {
  display: flex;
  width: 100%;
  height: 2rem;
  align-items: center;
}
.users-span-first {
  margin-top: 2rem;
}
.subheader-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  font-weight: 600;
  padding-left: 2.5rem;
}
.body-nav {
  height: 100%;
}
.x-icon {
  display: flex;
  width: 100%;
  height: 4rem;
  background-color: #1b2330;
}
.menu-bars {
  cursor: pointer;
  font-size: 2rem;
  margin: 1.2rem 0.8rem 0 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}
.menu-bars:hover {
  color: white;
}
.icon-item {
  position: relative;
  font-size: 1.2rem;
  padding: 0.5rem;
  vertical-align: middle;
}
.main-menu {
  background-color: #252f3e;
  height: calc(100% - 3.9rem);
  width: 15.5rem;
}
.main-menu ul {
  margin: 1rem 0;
}
.main-menu li {
  padding: 0.2rem;
  border-radius: 10px;
  margin: auto 1rem;
  list-style: none;
  cursor: pointer;
}
.main-menu li:hover {
  background-color: #8188928e;
}
.nav-items {
  color: rgba(255, 255, 255, 0.7);
}
.nav-text {
  font-weight: 500;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.7);
  padding-left: 0.6rem;
  vertical-align: middle;
}

.logout-sidebar {
  width: 15.5rem;
  background-color: #252f3e;
  display: flex;
  margin: 0;
  padding: 0;
  cursor: pointer;
  justify-content: center;
  margin: auto;
}
.logout-icon {
  font-size: 1.8rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 1rem 0;
}
.logout-span {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
  margin: 1rem 0.5rem;
}

.logout-icon:hover,
.logout-span:hover {
  color: white;
}
