/* generals */
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-end {
  justify-content: flex-end;
}
.close {
  display: none;
}
.active {
  background-color: #464e5bc0;
}

/* toolbar */
.wrapper-toolbar {
  display: flex;
  height: 100%;
  width: 100%;
  box-shadow: rgba(128, 126, 126, 0.178) 0px 4px 4px;
  transition: 0.5s;
  z-index: 1;
}
.toolbar {
  flex: 6;
  width: 90%;
  transition: 0.5s;
}
.main-page {
  width: 100%;
}
header {
  display: flex;
  width: 100%;
  height: 4.5vh;
  align-items: center;
  padding: 0.5rem;
  min-height: 30px;
}
.menu-icon-toolbar {
  color: #1b2330;
  font-size: 1.6rem;
  cursor: pointer;
  padding-left: 0.5rem;
}
.menu-icon-toolbar:hover {
  color: rgb(10, 9, 9);
  padding: 0.6rem;
}
.nav-links,
.toolbar-logo {
  display: flex;
  margin-right: 0.5rem;
  align-items: center;
}
.toolbar-links {
  display: flex;
  justify-content: flex-end;
}
.toolbar-link {
  list-style: none;
  text-decoration: none;
}
.icon-item-toolbar {
  position: relative;
  font-size: 1.2rem;
  padding: 0.5rem;
  vertical-align: middle;
  color: black;
}
.icon-item-dropdown {
  position: relative;
  font-size: 1.1rem;
  padding: 0.2rem;
  vertical-align: middle;
  color: black;
}
.toolbar-link:hover {
  border-radius: 50%;
  background-color: #e4e4e4;
}
.main-board {
  background-color: rgb(235, 233, 233);
  height: 100%;
}
.toolbar-logo {
  margin-right: 1rem;
}
.toolbar-avatar {
  height: 2.5rem;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 110px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10%;
  left: auto !important;
  right: 0 !important;
  top: 1.7rem;
}
.dropdown-content li {
  font-size: 13px;
  float: center;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content li:hover {
  background-color: #ddd;
  cursor: pointer;
  border-radius: 10%;
}
.dropdown-content {
  display: inline-block;
}
.avatar-name {
  font-size: 0.9rem;
  text-align: right;
  padding-left: 1rem;
  padding-top: 0.4rem;
}
.avatar-role {
  font-size: 0.7rem;
  text-align: right;
  font-weight: 400;
  color: green;
}
