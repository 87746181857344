@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper-subheader,
.wrapper-subheader_candidates {
  display: flex;
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: auto;
}
.wrapper-subheader_candidates {
  max-height: 0;
  transition: max-height 0.8s ease-out;
}
.wrapper-subheader_candidates.filters-opened {
  max-height: 100rem;
  transition: max-height 0.8s ease-in;
}
.subheader-form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.search-box {
  border: 1px solid rgb(238, 238, 238);
  border-radius: 6px;
  color: rgb(17, 24, 39);
  transition: all 0.2s ease;
  padding: 0 0.7rem;
  margin: 2.75rem auto 0 auto;
  height: 2rem;
  width: 17rem;
}
.search-box__candidates {
  background-color: white;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 6px;
  color: rgb(17, 24, 39);
  transition: all 0.2s ease;
  padding: 0 0.7rem;
  height: 2.3rem;
  width: 100%;
}
.search-box__candidates::-webkit-datetime-edit-fields-wrapper {
  color: silver;
}

input[type="date"]::before {
  /* content: attr(placeholder) !important; */
  margin-right: 0.5em;
  color: rgb(194, 194, 194);
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}
input[type="date"]:focus {
  content: normal !important;
  color: black;
}

.search-box__candidates:hover {
  border-color: #7a7a7abe;
}
.search-box__select {
  width: 16.5rem;
  margin: 1.5rem 2rem 0 auto;
  cursor: pointer;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  color: black;
}
.search-box:focus,
.search-box__candidates:focus {
  border-color: rgba(76, 76, 77, 0.842);
}
.btn-subheader {
  display: flex;
  /* padding: 0 1rem; */
  min-width: 17rem;
  margin: 2.25rem auto 0 auto;
  justify-content: flex-start;
}
.checkbox-container {
  margin: 2.75rem auto 0 auto;
  min-width: 17rem;
  margin-bottom: 1rem;
}
.checkbox {
  padding: 0.5rem;
  margin: 0.5rem;
}
.wrapper-subheader::-webkit-scrollbar-track {
  border-radius: 10px;
}
.wrapper-subheader::-webkit-scrollbar {
  width: 10px;
}
.wrapper-subheader::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.wrapper-subheader::-webkit-scrollbar-thumb:hover {
  background: rgb(184, 182, 182);
}

.btn-container {
  width: 97%;
  display: flex;
  margin: 1rem auto;
  justify-content: space-between;
}
.btn-clear {
  border: 0;
  background: #252f3e;
  color: #fff;
  font-weight: 600;
  padding: 0.5rem;
  border-radius: 8px;
  font-size: 12px;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.open-filters-div {
  padding: 0.5rem 1rem;
}
.open-filters {
  background-color: rgba(35, 149, 241, 0.87);
  font-size: 1rem;
}
.open-filters:hover {
  background-color: rgba(3, 131, 235, 0.589);
}
.open-filters-arrow {
  font-size: 1.2rem;
  vertical-align: sub;
}
.btn-action {
  border: 0;
  color: #fff;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  min-width: 3.6rem;
  margin-left: 0.5rem;
}
.delete {
  background: #d40202f1;
  font-size: 12px;
  width: 3.6rem;
}
.delete:hover {
  background-color: #d40202a8;
}
.view {
  color: rgb(255, 255, 255);
  margin: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  padding: 0.35rem;
  min-width: 3rem;
  background: #252f3e;
  font-size: 12px;
  text-align: center;
}
.view:hover {
  background: #252f3ec7;
}
.cancel {
  width: 7rem;
  height: 2.5rem;
  font-size: 16px;
}
.confirm:hover {
  background-color: #d40202c7;
}
.confirm {
  background: #d40202f1;
  width: 7rem;
  height: 2.5rem;
  font-size: 16px;
}
.create {
  background-color: #252f3e;
  width: 10rem;
  margin: 3rem auto;
  height: 2.5rem;
  font-size: 18px;
}
.create:hover {
  background-color: #252f3ecc;
}
.restore {
  background-color: #339903ec;
}
.restore:hover {
  background-color: #339903ab;
}
.disable {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.26);
  background: rgba(0, 0, 0, 0.12);
}
.filter {
  background: #252f3e;
  font-size: 14px;
  height: 2rem;
  width: 6rem;
  border: 0;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 1rem;
}
.filter_candidates {
  background: #252f3e;
  font-size: 14px;
  height: 2rem;
  width: 7.5rem;
}
.filter_candidates:hover,
.filter:hover {
  background: #252f3edc;
}
.import {
  background-color: #339903ec;
  width: 7.5rem;
  height: 2rem;
  border: 1px solit lightgray;
  border-radius: 6px;
  text-align: center;
  margin: 0.5rem;
  font-size: 15px;
  line-height: 2;
  color: white;
  cursor: pointer;
  padding: 0;
}
.actions {
  display: flex;
}
.actions-disabled {
  cursor: not-allowed;
  display: flex;
}
.actions-disabled .view {
  pointer-events: none;
}
.actions-disabled .delete {
  pointer-events: none;
}
.actions-disabled .restore {
  pointer-events: none;
}

.modal-restore {
  background-color: #339903ec;
  width: 7rem;
  height: 2.5rem;
  font-size: 16px;
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 0.4rem;
  margin: 1rem auto 4rem auto;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.form-container header {
  display: flex;
  margin: 0 0 2rem 0;
  text-align: center;
  border-radius: 10px 10px 0 0;
  justify-content: center;
  background-color: #1b2330e8;
  width: 100%;
  color: white;
  padding: 0.5rem 0;
}
.form-create-user {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
.input-wrapper-user {
  display: flex;
  flex-direction: column;
  width: 95%;
  justify-content: flex-start;
  margin: auto;
}
.input-create-user {
  width: 80%;
  height: 2.5rem;
  margin: 0.2rem auto 1.2rem auto;
  padding: 0 0.7rem;
  font-size: 16px;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 6px;
  color: rgb(17, 24, 39);
  transition: all 0.2s ease;
  background-color: #fff;
}
.input-select-create-user {
  width: 31.5rem;
  height: 3rem;
  margin: 1rem 4rem;
  transition: all 0.2s ease;
}
.input-create-user:focus {
  border-color: #2684ff;
}
.label {
  margin: 0 2.5rem;
}
.show-pswd {
  display: flex;
  /* align-items: center; */
  width: 95%;
  margin: auto;
}
.show-pswd__icon {
  cursor: pointer;
  font-size: 2rem;
  margin-right: auto;
  color: rgba(0, 0, 0, 0.384);
  padding: 0.5rem;
  position: relative;
}
.margin-right {
  margin-right: -3.1rem;
}
.table {
  width: 100%;
}
.icon-create-user {
  font-size: 1.3rem;
  padding: 0 0.5rem;
  vertical-align: sub;
}
.input-create-user.validate-message {
  margin-bottom: 0;
}
.validate-message {
  width: 85%;
  font-size: 11px;
  margin: 0 0 0 3.8rem;
  color: red;
}
.error-validation {
  color: red;
  width: 85%;
}
.succes-validation {
  color: green;
}
.input-create-user.succes {
  border-color: green;
}
.input-create-user.error {
  border-color: red;
}
.icon-modal {
  font-size: 2rem;
  color: red;
  vertical-align: middle;
}
.green {
  color: green;
}
.select-input {
  width: 31.5rem;
  cursor: pointer;
}
.spinner > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal {
  transition: all 2s ease-in-out;
  position: absolute;
  width: 30rem;
  height: 15rem;
  margin: auto;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(197, 197, 197, 0.856);
  float: left;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 5%;
  box-shadow: rgba(128, 126, 126, 0.178) 0px 4px 4px;
  z-index: 999;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f6f7f977;
  z-index: 1;
}
.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.modal-header {
  width: 70%;
  height: 20%;
  padding: 0.5rem;
  margin: 0 auto;
  justify-content: space-evenly;
}
.modal-body {
  width: 100%;
  height: 40%;
}
.modal-footer {
  height: 20%;
}
.line {
  width: 80%;
  margin: auto;
  border: 1px solid rgba(128, 128, 128, 0.363);
}

.select-wrapper {
  width: 16%;
  display: flex;
  margin: 1rem 2.1rem 0 auto;
}
@media only screen and (min-width: 1500px) {
  .form-container {
    width: 35%;
  }
  .label {
    margin-left: 3.5rem;
  }
}
@media only screen and (max-width: 1500px) {
  .search-box {
    width: 8.5rem;
  }
  .btn-subheader {
    min-width: 6rem;
  }
}
@media only screen and (max-width: 1300px) {
  .select-wrapper {
    width: 25%;
    margin: 0.5rem;
  }
  .search-box {
    width: 8.5rem;
  }
  .btn-subheader {
    min-width: 6rem;
  }
}

@media only screen and (max-width: 950px) {
  .form-container {
    width: 85%;
  }
}

@media only screen and (max-width: 700px) {
  .select-wrapper {
    width: 45%;
    margin: 0.5rem;
  }
}

@media only screen and (max-width: 560px) {
  .Modal {
    width: 20rem;
    height: 10rem;
  }
  .Modal h4 {
    font-size: 12px;
  }
  .Modal p {
    font-size: 15px;
  }
  .Modal strong {
    font-size: 13px;
  }
  .icon-modal {
    font-size: 1.3rem;
  }
  .confirm,
  .cancel {
    width: 4.5rem;
    height: 2rem;
  }
  .form-container {
    width: 90%;
  }

  .select-input {
    width: 88%;
  }
  .label {
    margin: 0 2.5rem;
    font-size: 14px;
  }
  .validate-message {
    margin: 0 1.5rem;
  }
  .form-container h1 {
    font-size: 20px;
  }
  .search-box {
    width: 70%;
    margin-top: 0.7rem;
  }
  .select-wrapper {
    width: 85%;
    margin: 0.5rem auto;
  }
  .checkbox-label {
    font-size: 14px;
    margin: 0.5rem 0 0.5rem 1rem;
  }
  .subheader-form {
    flex-wrap: wrap;
  }
  .filter {
    margin: 0.5rem auto;
  }
  .margin-right {
    margin-right: -3.4rem;
  }
  .checkbox-container {
    margin-top: 1rem;
    order: -1;
  }
}

@media only screen and (max-width: 560px) {
  .form-container {
    width: 95%;
    position: fixed;
    overflow-y: scroll;
  }
}

.candidates_container__Ek9lJ {
  height: 90%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin: auto;
  transition: all 0.1s ease;
  width: 100%;
}
.candidates_header__17w5G {
  height: 2.1rem;
  background: linear-gradient(to right, #334461, #252f3e);
  width: 80%;
  margin: 0 auto 1rem auto;
  justify-content: center;
  border-radius: 6px;
}
.candidates_container_fluid__duaGo {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.candidates_container_fluid_upload__2xjxM {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-left: 3.6%;
}
.candidates_upload_div__2ndFA {
  display: flex;
  margin-left: 3.6%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.candidates_flex_start__3lo6v {
  justify-content: flex-start;
  margin: 0;
}
.candidates_input_wrapper__3rrDE {
  display: flex;
  width: 26%;
  padding: 0.4rem 0.5rem;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
.candidates_input_wrapper_double__2C0w8 {
  display: flex;
  width: 28%;
  padding: 0.7rem 0.5rem;
  justify-content: space-around;
  margin: auto;
}
.candidates_input_upload__1_XPW {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: auto 0;
  width: 95%;
  height: 6rem;
  border: 0.5px solid rgba(190, 190, 190, 0.911);
  border-radius: 6px;
}
.candidates_input_field__ZL98j {
  height: 2.3rem;
  margin-bottom: 0.5rem;
  padding: 0 0.7rem;
  font-size: 16px;
  border: 0.5px solid rgba(190, 190, 190, 0.911);
  border-radius: 6px;
  color: rgb(17, 24, 39);
  transition: all 0.2s ease;
}
.candidates_input_field__ZL98j:focus {
  border: 1px solid #808080;
}
.candidates_input_status__3nfwn {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  padding: 0;
}
.candidates_input_status__3nfwn:first-of-type {
  margin-right: 1.5rem;
}
.candidates_label__kFh04 {
  padding: 0 0.7rem;
}
.candidates_options__3ABHZ {
  background-color: white;
}
.candidates_text_center__28YbM {
  color: azure;
  font-weight: 400;
}
.candidates_btn_wrapper__EScwF {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
  padding-bottom: 3rem;
}
.candidates_btn__PMOO3 {
  border: 0;
  background: #252f3e;
  color: #fff;
  font-weight: 600;
  min-width: 15rem;
  padding: 0.5rem;
  border-radius: 15px;
  font-size: 18px;
  letter-spacing: 0.5px;
  cursor: pointer;
  margin: 1rem auto;
}
.candidates_btn__PMOO3:hover {
  background: #252f3ec9;
}
.candidates_icon__2WS10 {
  font-size: 1.3rem;
  padding-right: 0.5rem;
  vertical-align: sub;
  color: #301b2e;
}
.candidates_break_line__Hs9eb {
  width: 95%;
  margin: 0 auto 2rem auto;
}
.candidates_container__Ek9lJ h4 {
  margin-left: 2.5%;
}
.candidates_disabled__17hrT {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.26);
  background: rgba(0, 0, 0, 0.12);
}
.candidates_error_validation__3TgIg {
  color: red;
}
.candidates_succes_validation__3opJe {
  color: green;
}
.candidates_input_field__ZL98j.candidates_succes__nk4t6 {
  border-color: green;
}
.candidates_input_field__ZL98j.candidates_error__Ki3H3 {
  border-color: red;
}
.candidates_error_upload__10y67 {
  margin-left: 3rem;
  /* font-family: sans-serif; */
  font-size: medium;
  color: red;
}
.candidates_input_wrapper__3rrDE .candidates_error_validation__3TgIg,
.candidates_succes_validation__3opJe {
  font-size: 11px;
  margin-left: 1.5%;
  display: block;
  z-index: 5;
}
.candidates_upload_label__3f56a.candidates_green__rrpiH {
  background-color: #27ae60;
}

.candidates_upload_wrapper__VV11p {
  display: flex;
  flex-direction: column;
  margin-left: 2.5rem;
  padding: 0.5rem;
  width: 28%;
}
.candidates_upload_container__ms5bb {
  display: flex;
}
.candidates_upload_file__name__3vZCr {
  max-height: 2rem;
  padding: 0.5rem 0.5rem;
  font-size: 14px;
  color: blueviolet;
}

.candidates_react-select__menu-list__2qbx1 {
  z-index: 10;
}

option {
  border: 1px solid rgb(150, 148, 148);
  border-radius: 6px;
}

input[type="file"] {
  display: none;
}
textarea {
  resize: vertical;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
}
.candidates_upload_label__3f56a {
  cursor: pointer;
  border: 0.5px solid rgba(190, 190, 190, 0.911);
  border-radius: 6px;
  overflow: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 120%;
  margin: 1rem 0;
  padding: 0.5rem;
}
.candidates_upload_label__3f56a:hover {
  background: #afafaf;
}

@media only screen and (max-width: 1000px) {
  .candidates_container__Ek9lJ {
    width: 100%;
    overflow-y: scroll;
  }
  .candidates_input_date__VyGdZ {
    width: 48%;
  }
  .candidates_input_wrapper__3rrDE {
    width: 35%;
  }
  .candidates_header__17w5G {
    height: 2rem;
  }
  .candidates_text_center__28YbM {
    font-size: 16px;
  }
}
@media only screen and (max-width: 770px) {
  .candidates_container__Ek9lJ {
    width: 90%;
    overflow-y: scroll;
  }
  .candidates_input_date__VyGdZ {
    width: 100%;
  }
  .candidates_input_wrapper__3rrDE {
    width: 95%;
  }
  .candidates_header__17w5G {
    height: 2rem;
  }
  .candidates_text_center__28YbM {
    font-size: 16px;
  }
}

#notfound {
    position: relative;
    height: 80vh;
  }
  
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
  }
  
  .notfound .notfound-404 {
    position: relative;
    height: 240px;
  }
  
  .notfound .notfound-404 h1 {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 252px;
    font-weight: 900;
    margin: 0px;
    color: #262626;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px;
  }
  
  .notfound .notfound-404 h1>span {
    text-shadow: -8px 0px 0px #fff;
  }
  
  .notfound .notfound-404 h3 {
    font-family: 'Cabin', sans-serif;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #262626;
    margin: 0px;
    letter-spacing: 3px;
    padding-left: 6px;
  }
  
  .notfound h2 {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  
  @media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
      height: 200px;
    }
    .notfound .notfound-404 h1 {
      font-size: 200px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
      height: 162px;
    }
    .notfound .notfound-404 h1 {
      font-size: 162px;
      height: 150px;
      line-height: 162px;
    }
    .notfound h2 {
      font-size: 16px;
    }
  }
/* resets */

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}

/* generals */
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-end {
  justify-content: flex-end;
}
.close {
  display: none;
}
.active {
  background-color: #464e5bc0;
}

.wrapper-sidebar::-webkit-scrollbar-track {
  border-radius: 7px;
}
.wrapper-sidebar::-webkit-scrollbar {
  width: 7px;
}
.wrapper-sidebar::-webkit-scrollbar-thumb {
  border-radius: 7px;
}
.wrapper-sidebar::-webkit-scrollbar-thumb:hover {
  background: #1d365faf;
}

/* sidebar */

.wrapper-sidebar {
  display: flex;
  overflow-x: hidden;
  position: fixed;
  width: 0rem;
  transition: all ease 0.3s;
  z-index: 1;
}
.container-sidebar {
  height: 100vh;
}
.user-name {
  color: rgba(255, 255, 255, 0.863);
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  padding-top: 0.5rem;
}
.user-mail {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}
.wrapper-logo {
  position: relative;
}
.logo-sidebar {
  border: 0.6rem solid #252f3e;
  border-radius: 50%;
  height: 3.8rem;
  margin-top: 1.65rem;
}
.header-nav {
  background-color: #1b2330;
  width: 100%;
  height: 9.5rem;
  text-align: center;
}
.users-span {
  display: flex;
  width: 100%;
  height: 2rem;
  align-items: center;
}
.users-span-first {
  margin-top: 2rem;
}
.subheader-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  font-weight: 600;
  padding-left: 2.5rem;
}
.body-nav {
  height: 100%;
}
.x-icon {
  display: flex;
  width: 100%;
  height: 4rem;
  background-color: #1b2330;
}
.menu-bars {
  cursor: pointer;
  font-size: 2rem;
  margin: 1.2rem 0.8rem 0 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}
.menu-bars:hover {
  color: white;
}
.icon-item {
  position: relative;
  font-size: 1.2rem;
  padding: 0.5rem;
  vertical-align: middle;
}
.main-menu {
  background-color: #252f3e;
  height: calc(100% - 3.9rem);
  width: 15.5rem;
}
.main-menu ul {
  margin: 1rem 0;
}
.main-menu li {
  padding: 0.2rem;
  border-radius: 10px;
  margin: auto 1rem;
  list-style: none;
  cursor: pointer;
}
.main-menu li:hover {
  background-color: #8188928e;
}
.nav-items {
  color: rgba(255, 255, 255, 0.7);
}
.nav-text {
  font-weight: 500;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.7);
  padding-left: 0.6rem;
  vertical-align: middle;
}

.logout-sidebar {
  width: 15.5rem;
  background-color: #252f3e;
  display: flex;
  margin: 0;
  padding: 0;
  cursor: pointer;
  justify-content: center;
  margin: auto;
}
.logout-icon {
  font-size: 1.8rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 1rem 0;
}
.logout-span {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
  margin: 1rem 0.5rem;
}

.logout-icon:hover,
.logout-span:hover {
  color: white;
}

/* generals */
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-end {
  justify-content: flex-end;
}
.close {
  display: none;
}
.active {
  background-color: #464e5bc0;
}

/* toolbar */
.wrapper-toolbar {
  display: flex;
  height: 100%;
  width: 100%;
  box-shadow: rgba(128, 126, 126, 0.178) 0px 4px 4px;
  transition: 0.5s;
  z-index: 1;
}
.toolbar {
  flex: 6 1;
  width: 90%;
  transition: 0.5s;
}
.main-page {
  width: 100%;
}
header {
  display: flex;
  width: 100%;
  height: 4.5vh;
  align-items: center;
  padding: 0.5rem;
  min-height: 30px;
}
.menu-icon-toolbar {
  color: #1b2330;
  font-size: 1.6rem;
  cursor: pointer;
  padding-left: 0.5rem;
}
.menu-icon-toolbar:hover {
  color: rgb(10, 9, 9);
  padding: 0.6rem;
}
.nav-links,
.toolbar-logo {
  display: flex;
  margin-right: 0.5rem;
  align-items: center;
}
.toolbar-links {
  display: flex;
  justify-content: flex-end;
}
.toolbar-link {
  list-style: none;
  text-decoration: none;
}
.icon-item-toolbar {
  position: relative;
  font-size: 1.2rem;
  padding: 0.5rem;
  vertical-align: middle;
  color: black;
}
.icon-item-dropdown {
  position: relative;
  font-size: 1.1rem;
  padding: 0.2rem;
  vertical-align: middle;
  color: black;
}
.toolbar-link:hover {
  border-radius: 50%;
  background-color: #e4e4e4;
}
.main-board {
  background-color: rgb(235, 233, 233);
  height: 100%;
}
.toolbar-logo {
  margin-right: 1rem;
}
.toolbar-avatar {
  height: 2.5rem;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 110px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10%;
  left: auto !important;
  right: 0 !important;
  top: 1.7rem;
}
.dropdown-content li {
  font-size: 13px;
  float: center;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content li:hover {
  background-color: #ddd;
  cursor: pointer;
  border-radius: 10%;
}
.dropdown-content {
  display: inline-block;
}
.avatar-name {
  font-size: 0.9rem;
  text-align: right;
  padding-left: 1rem;
  padding-top: 0.4rem;
}
.avatar-role {
  font-size: 0.7rem;
  text-align: right;
  font-weight: 400;
  color: green;
}

.container-app {
  display: flex;
  overflow: hidden;
}

.wrapper-toolbar {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}
.component-page {
  width: 100%;
  /* max-height: 100vh; */
  overflow: hidden;
  transition: margin-left 0.5s;
}
.container-component {
  overflow-y: scroll;
  background-color: #f6f7f9;
  /* height: 100vh; */
  padding: 0.2rem;
  margin-left: 0.3rem;
  transition: all ease 0.5s;
  margin-top: 0.5rem;
}

.sidebar-displayed {
  width: 15.5rem;
  transition: all ease 0.5s;
}
.component-margin {
  margin-left: 15.5rem;
}

.container-component::-webkit-scrollbar-track {
  border-radius: 10px;
}
.container-component::-webkit-scrollbar {
  width: 10px;
}
.container-component::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.container-component::-webkit-scrollbar-thumb:hover {
  background: rgb(184, 182, 182);
}

.wellcome-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: auto;
  width: 100%;
  height: 80%;
}
.wellcome-header {
  text-align: center;
  font-size: 7rem;
  font-weight: 400;
}
.wellcome-subheader {
  text-align: center;
  font-size: 4rem;
  font-weight: 300;
}

@media only screen and (max-width: 960px) {
  .wellcome-header {
    font-size: 5rem;
    padding-right: 1rem;
  }
  .wellcome-subheader {
    font-size: 3rem;
    padding-right: 1rem;
  }
  .wellcome-div {
    height: 65%;
  }
}

@media only screen and (max-width: 460px) {
  .wellcome-header {
    font-size: 4rem;
    padding-right: 1rem;
  }
  .wellcome-subheader {
    font-size: 2rem;
    padding-right: 1rem;
  }
  .wellcome-div {
    position: fixed;
    height: 65%;
  }
  .container-component {
    overflow-y: scroll;
  }
}

/* prettier-ignore */

html {
  overflow-x: hidden;
}

/* resets */
* {
  margin: 0;
  padding: 0;
  outline: 0;
}
.wrapper {
  box-sizing: border-box;
}
.wrapper:before,
.wrapper:after {
  box-sizing: border-box;
}

/* general */

body {
  background-color: #f6f7f9;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  min-width: 280px;
  height: -webkit-max-content;
  height: max-content;
}
a {
  color: rgb(34, 211, 238);
  text-decoration: none;
  cursor: pointer;
}

.wrapper a {
  color: rgb(34, 211, 238);
  text-decoration: none;
  cursor: pointer;
}
.wrapper a:hover {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}
.error-text {
  color: red;
}
.succes-text {
  color: green;
}

::-webkit-input-placeholder {
  color: rgb(194, 194, 194);
  font-size: 13px;
}
:-moz-placeholder {
  color: rgb(194, 194, 194);
  font-size: 13px;
}
::-moz-placeholder {
  color: rgb(194, 194, 194);
  font-size: 13px;
}
:-ms-input-placeholder {
  color: rgb(194, 194, 194);
  font-size: 13px;
}

/* wrapper */
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  margin: auto;
}
.wrapper .container {
  background-color: #ffffff;
  width: 25rem;
  margin: 0 1rem;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

/* wrapper logo */
.wrapper .container .logo-wrapper {
  padding-top: 2rem;
}
.wrapper .container .logo-wrapper .logo {
  display: flex;
  margin: auto;
  width: 40%;
  height: 40%;
}

/* wrapper title */
.wrapper .container .presentation {
  font-weight: 600;
  padding: 2rem 0;
}

/* wrapper form */
.wrapper .container .form-wrapper {
  padding: 0 1.5rem;
}
.wrapper .container .form-wrapper .form .input-wrapper {
  display: flex;
}
.wrapper .container .form-wrapper .form .input {
  width: 100%;
  height: 3rem;
  margin-bottom: 1.9rem;
  padding: 0 0.7rem;
  font-size: 16px;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 6px;
  color: rgb(17, 24, 39);
  transition: all 0.2s ease;
}
.wrapper .container .form-wrapper .form .input:focus {
  border-color: rgb(34, 211, 238);
}
.wrapper .container .form-wrapper .form .input.error {
  border-color: red;
  color: red;
}
.wrapper .container .form-wrapper .form .input.succes {
  border-color: green;
}
.wrapper .container .form-wrapper .form .message {
  width: 100%;
  position: absolute;
  margin: 3.3rem 0 0.5rem 0.5rem;
  font-size: 11px;
  color: red;
}
.wrapper .container .form-wrapper .form .forgot {
  margin-top: 0.5rem;
  font-size: 14px;
}
.wrapper .container .form-wrapper .form .button-wrapper {
  margin-top: 1.5rem;
}
.wrapper .container .form-wrapper .form .button-wrapper .button {
  border: 0;
  background: #252f3e;
  color: #fff;
  font-weight: 600;
  min-width: 13rem;
  padding: 0.5rem;
  border-radius: 15px;
  font-size: 14px;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.wrapper .container .form-wrapper .form .button-wrapper .button:hover {
  background: #000;
}
.wrapper .container .form-wrapper .form .button-wrapper .button.disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.26);
  background: rgba(0, 0, 0, 0.12);
}

.wrapper .container .or-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
}
.wrapper .container .or-wrapper .line {
  width: 2rem;
  border: none;
  height: 1px;
  margin: 0;
  background: rgba(0, 0, 0, 0.12);
}
.wrapper .container .or-wrapper .text {
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 10px;
}

/* wrapper no-account */
.wrapper .no-account {
  font-size: 14px;
  margin: 1.5rem 0;
}

.message__input {
  padding: 0.3rem;
}
.login-show-pswd__icon {
  cursor: pointer;
  font-size: 1.8rem;
  color: rgba(0, 0, 0, 0.384);
  padding: 0.5rem;
  margin: 0.3rem auto;
}
.green {
  color: rgba(3, 83, 3, 0.575);
}
.login-margin-right {
  margin-right: -2.7rem;
}

