.wrapper-subheader,
.wrapper-subheader_candidates {
  display: flex;
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: auto;
}
.wrapper-subheader_candidates {
  max-height: 0;
  transition: max-height 0.8s ease-out;
}
.wrapper-subheader_candidates.filters-opened {
  max-height: 100rem;
  transition: max-height 0.8s ease-in;
}
.subheader-form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.search-box {
  border: 1px solid rgb(238, 238, 238);
  border-radius: 6px;
  color: rgb(17, 24, 39);
  transition: all 0.2s ease;
  padding: 0 0.7rem;
  margin: 2.75rem auto 0 auto;
  height: 2rem;
  width: 17rem;
}
.search-box__candidates {
  background-color: white;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 6px;
  color: rgb(17, 24, 39);
  transition: all 0.2s ease;
  padding: 0 0.7rem;
  height: 2.3rem;
  width: 100%;
}
.search-box__candidates::-webkit-datetime-edit-fields-wrapper {
  color: silver;
}

input[type="date"]::before {
  /* content: attr(placeholder) !important; */
  margin-right: 0.5em;
  color: rgb(194, 194, 194);
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}
input[type="date"]:focus {
  content: normal !important;
  color: black;
}

.search-box__candidates:hover {
  border-color: #7a7a7abe;
}
.search-box__select {
  width: 16.5rem;
  margin: 1.5rem 2rem 0 auto;
  cursor: pointer;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  color: black;
}
.search-box:focus,
.search-box__candidates:focus {
  border-color: rgba(76, 76, 77, 0.842);
}
.btn-subheader {
  display: flex;
  /* padding: 0 1rem; */
  min-width: 17rem;
  margin: 2.25rem auto 0 auto;
  justify-content: flex-start;
}
.checkbox-container {
  margin: 2.75rem auto 0 auto;
  min-width: 17rem;
  margin-bottom: 1rem;
}
.checkbox {
  padding: 0.5rem;
  margin: 0.5rem;
}
.wrapper-subheader::-webkit-scrollbar-track {
  border-radius: 10px;
}
.wrapper-subheader::-webkit-scrollbar {
  width: 10px;
}
.wrapper-subheader::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.wrapper-subheader::-webkit-scrollbar-thumb:hover {
  background: rgb(184, 182, 182);
}

.btn-container {
  width: 97%;
  display: flex;
  margin: 1rem auto;
  justify-content: space-between;
}
.btn-clear {
  border: 0;
  background: #252f3e;
  color: #fff;
  font-weight: 600;
  padding: 0.5rem;
  border-radius: 8px;
  font-size: 12px;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.open-filters-div {
  padding: 0.5rem 1rem;
}
.open-filters {
  background-color: rgba(35, 149, 241, 0.87);
  font-size: 1rem;
}
.open-filters:hover {
  background-color: rgba(3, 131, 235, 0.589);
}
.open-filters-arrow {
  font-size: 1.2rem;
  vertical-align: sub;
}
.btn-action {
  border: 0;
  color: #fff;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  min-width: 3.6rem;
  margin-left: 0.5rem;
}
.delete {
  background: #d40202f1;
  font-size: 12px;
  width: 3.6rem;
}
.delete:hover {
  background-color: #d40202a8;
}
.view {
  color: rgb(255, 255, 255);
  margin: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  padding: 0.35rem;
  min-width: 3rem;
  background: #252f3e;
  font-size: 12px;
  text-align: center;
}
.view:hover {
  background: #252f3ec7;
}
.cancel {
  width: 7rem;
  height: 2.5rem;
  font-size: 16px;
}
.confirm:hover {
  background-color: #d40202c7;
}
.confirm {
  background: #d40202f1;
  width: 7rem;
  height: 2.5rem;
  font-size: 16px;
}
.create {
  background-color: #252f3e;
  width: 10rem;
  margin: 3rem auto;
  height: 2.5rem;
  font-size: 18px;
}
.create:hover {
  background-color: #252f3ecc;
}
.restore {
  background-color: #339903ec;
}
.restore:hover {
  background-color: #339903ab;
}
.disable {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.26);
  background: rgba(0, 0, 0, 0.12);
}
.filter {
  background: #252f3e;
  font-size: 14px;
  height: 2rem;
  width: 6rem;
  border: 0;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 1rem;
}
.filter_candidates {
  background: #252f3e;
  font-size: 14px;
  height: 2rem;
  width: 7.5rem;
}
.filter_candidates:hover,
.filter:hover {
  background: #252f3edc;
}
.import {
  background-color: #339903ec;
  width: 7.5rem;
  height: 2rem;
  border: 1px solit lightgray;
  border-radius: 6px;
  text-align: center;
  margin: 0.5rem;
  font-size: 15px;
  line-height: 2;
  color: white;
  cursor: pointer;
  padding: 0;
}
.actions {
  display: flex;
}
.actions-disabled {
  cursor: not-allowed;
  display: flex;
}
.actions-disabled .view {
  pointer-events: none;
}
.actions-disabled .delete {
  pointer-events: none;
}
.actions-disabled .restore {
  pointer-events: none;
}

.modal-restore {
  background-color: #339903ec;
  width: 7rem;
  height: 2.5rem;
  font-size: 16px;
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: fit-content;
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 0.4rem;
  margin: 1rem auto 4rem auto;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.form-container header {
  display: flex;
  margin: 0 0 2rem 0;
  text-align: center;
  border-radius: 10px 10px 0 0;
  justify-content: center;
  background-color: #1b2330e8;
  width: 100%;
  color: white;
  padding: 0.5rem 0;
}
.form-create-user {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
.input-wrapper-user {
  display: flex;
  flex-direction: column;
  width: 95%;
  justify-content: flex-start;
  margin: auto;
}
.input-create-user {
  width: 80%;
  height: 2.5rem;
  margin: 0.2rem auto 1.2rem auto;
  padding: 0 0.7rem;
  font-size: 16px;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 6px;
  color: rgb(17, 24, 39);
  transition: all 0.2s ease;
  background-color: #fff;
}
.input-select-create-user {
  width: 31.5rem;
  height: 3rem;
  margin: 1rem 4rem;
  transition: all 0.2s ease;
}
.input-create-user:focus {
  border-color: #2684ff;
}
.label {
  margin: 0 2.5rem;
}
.show-pswd {
  display: flex;
  /* align-items: center; */
  width: 95%;
  margin: auto;
}
.show-pswd__icon {
  cursor: pointer;
  font-size: 2rem;
  margin-right: auto;
  color: rgba(0, 0, 0, 0.384);
  padding: 0.5rem;
  position: relative;
}
.margin-right {
  margin-right: -3.1rem;
}
.table {
  width: 100%;
}
.icon-create-user {
  font-size: 1.3rem;
  padding: 0 0.5rem;
  vertical-align: sub;
}
.input-create-user.validate-message {
  margin-bottom: 0;
}
.validate-message {
  width: 85%;
  font-size: 11px;
  margin: 0 0 0 3.8rem;
  color: red;
}
.error-validation {
  color: red;
  width: 85%;
}
.succes-validation {
  color: green;
}
.input-create-user.succes {
  border-color: green;
}
.input-create-user.error {
  border-color: red;
}
.icon-modal {
  font-size: 2rem;
  color: red;
  vertical-align: middle;
}
.green {
  color: green;
}
.select-input {
  width: 31.5rem;
  cursor: pointer;
}
.spinner > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal {
  transition: all 2s ease-in-out;
  position: absolute;
  width: 30rem;
  height: 15rem;
  margin: auto;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(197, 197, 197, 0.856);
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5%;
  box-shadow: rgba(128, 126, 126, 0.178) 0px 4px 4px;
  z-index: 999;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f6f7f977;
  z-index: 1;
}
.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.modal-header {
  width: 70%;
  height: 20%;
  padding: 0.5rem;
  margin: 0 auto;
  justify-content: space-evenly;
}
.modal-body {
  width: 100%;
  height: 40%;
}
.modal-footer {
  height: 20%;
}
.line {
  width: 80%;
  margin: auto;
  border: 1px solid rgba(128, 128, 128, 0.363);
}

.select-wrapper {
  width: 16%;
  display: flex;
  margin: 1rem 2.1rem 0 auto;
}
@media only screen and (min-width: 1500px) {
  .form-container {
    width: 35%;
  }
  .label {
    margin-left: 3.5rem;
  }
}
@media only screen and (max-width: 1500px) {
  .search-box {
    width: 8.5rem;
  }
  .btn-subheader {
    min-width: 6rem;
  }
}
@media only screen and (max-width: 1300px) {
  .select-wrapper {
    width: 25%;
    margin: 0.5rem;
  }
  .search-box {
    width: 8.5rem;
  }
  .btn-subheader {
    min-width: 6rem;
  }
}

@media only screen and (max-width: 950px) {
  .form-container {
    width: 85%;
  }
}

@media only screen and (max-width: 700px) {
  .select-wrapper {
    width: 45%;
    margin: 0.5rem;
  }
}

@media only screen and (max-width: 560px) {
  .Modal {
    width: 20rem;
    height: 10rem;
  }
  .Modal h4 {
    font-size: 12px;
  }
  .Modal p {
    font-size: 15px;
  }
  .Modal strong {
    font-size: 13px;
  }
  .icon-modal {
    font-size: 1.3rem;
  }
  .confirm,
  .cancel {
    width: 4.5rem;
    height: 2rem;
  }
  .form-container {
    width: 90%;
  }

  .select-input {
    width: 88%;
  }
  .label {
    margin: 0 2.5rem;
    font-size: 14px;
  }
  .validate-message {
    margin: 0 1.5rem;
  }
  .form-container h1 {
    font-size: 20px;
  }
  .search-box {
    width: 70%;
    margin-top: 0.7rem;
  }
  .select-wrapper {
    width: 85%;
    margin: 0.5rem auto;
  }
  .checkbox-label {
    font-size: 14px;
    margin: 0.5rem 0 0.5rem 1rem;
  }
  .subheader-form {
    flex-wrap: wrap;
  }
  .filter {
    margin: 0.5rem auto;
  }
  .margin-right {
    margin-right: -3.4rem;
  }
  .checkbox-container {
    margin-top: 1rem;
    order: -1;
  }
}

@media only screen and (max-width: 560px) {
  .form-container {
    width: 95%;
    position: fixed;
    overflow-y: scroll;
  }
}
