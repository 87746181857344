.container-app {
  display: flex;
  overflow: hidden;
}

.wrapper-toolbar {
  display: flex;
  height: fit-content;
  width: 100%;
}
.component-page {
  width: 100%;
  /* max-height: 100vh; */
  overflow: hidden;
  transition: margin-left 0.5s;
}
.container-component {
  overflow-y: scroll;
  background-color: #f6f7f9;
  /* height: 100vh; */
  padding: 0.2rem;
  margin-left: 0.3rem;
  transition: all ease 0.5s;
  margin-top: 0.5rem;
}

.sidebar-displayed {
  width: 15.5rem;
  transition: all ease 0.5s;
}
.component-margin {
  margin-left: 15.5rem;
}

.container-component::-webkit-scrollbar-track {
  border-radius: 10px;
}
.container-component::-webkit-scrollbar {
  width: 10px;
}
.container-component::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.container-component::-webkit-scrollbar-thumb:hover {
  background: rgb(184, 182, 182);
}

.wellcome-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: auto;
  width: 100%;
  height: 80%;
}
.wellcome-header {
  text-align: center;
  font-size: 7rem;
  font-weight: 400;
}
.wellcome-subheader {
  text-align: center;
  font-size: 4rem;
  font-weight: 300;
}

@media only screen and (max-width: 960px) {
  .wellcome-header {
    font-size: 5rem;
    padding-right: 1rem;
  }
  .wellcome-subheader {
    font-size: 3rem;
    padding-right: 1rem;
  }
  .wellcome-div {
    height: 65%;
  }
}

@media only screen and (max-width: 460px) {
  .wellcome-header {
    font-size: 4rem;
    padding-right: 1rem;
  }
  .wellcome-subheader {
    font-size: 2rem;
    padding-right: 1rem;
  }
  .wellcome-div {
    position: fixed;
    height: 65%;
  }
  .container-component {
    overflow-y: scroll;
  }
}
