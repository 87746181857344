/* prettier-ignore */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

html {
  overflow-x: hidden;
}

/* resets */
* {
  margin: 0;
  padding: 0;
  outline: 0;
}
.wrapper {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.wrapper:before,
.wrapper:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* general */

body {
  background-color: #f6f7f9;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  min-width: 280px;
  height: max-content;
}
a {
  color: rgb(34, 211, 238);
  text-decoration: none;
  cursor: pointer;
}

.wrapper a {
  color: rgb(34, 211, 238);
  text-decoration: none;
  cursor: pointer;
}
.wrapper a:hover {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}
.error-text {
  color: red;
}
.succes-text {
  color: green;
}

::-webkit-input-placeholder {
  color: rgb(194, 194, 194);
  font-size: 13px;
}
:-moz-placeholder {
  color: rgb(194, 194, 194);
  font-size: 13px;
}
::-moz-placeholder {
  color: rgb(194, 194, 194);
  font-size: 13px;
}
:-ms-input-placeholder {
  color: rgb(194, 194, 194);
  font-size: 13px;
}

/* wrapper */
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  margin: auto;
}
.wrapper .container {
  background-color: #ffffff;
  width: 25rem;
  margin: 0 1rem;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

/* wrapper logo */
.wrapper .container .logo-wrapper {
  padding-top: 2rem;
}
.wrapper .container .logo-wrapper .logo {
  display: flex;
  margin: auto;
  width: 40%;
  height: 40%;
}

/* wrapper title */
.wrapper .container .presentation {
  font-weight: 600;
  padding: 2rem 0;
}

/* wrapper form */
.wrapper .container .form-wrapper {
  padding: 0 1.5rem;
}
.wrapper .container .form-wrapper .form .input-wrapper {
  display: flex;
}
.wrapper .container .form-wrapper .form .input {
  width: 100%;
  height: 3rem;
  margin-bottom: 1.9rem;
  padding: 0 0.7rem;
  font-size: 16px;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 6px;
  color: rgb(17, 24, 39);
  transition: all 0.2s ease;
}
.wrapper .container .form-wrapper .form .input:focus {
  border-color: rgb(34, 211, 238);
}
.wrapper .container .form-wrapper .form .input.error {
  border-color: red;
  color: red;
}
.wrapper .container .form-wrapper .form .input.succes {
  border-color: green;
}
.wrapper .container .form-wrapper .form .message {
  width: 100%;
  position: absolute;
  margin: 3.3rem 0 0.5rem 0.5rem;
  font-size: 11px;
  color: red;
}
.wrapper .container .form-wrapper .form .forgot {
  margin-top: 0.5rem;
  font-size: 14px;
}
.wrapper .container .form-wrapper .form .button-wrapper {
  margin-top: 1.5rem;
}
.wrapper .container .form-wrapper .form .button-wrapper .button {
  border: 0;
  background: #252f3e;
  color: #fff;
  font-weight: 600;
  min-width: 13rem;
  padding: 0.5rem;
  border-radius: 15px;
  font-size: 14px;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.wrapper .container .form-wrapper .form .button-wrapper .button:hover {
  background: #000;
}
.wrapper .container .form-wrapper .form .button-wrapper .button.disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.26);
  background: rgba(0, 0, 0, 0.12);
}

.wrapper .container .or-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
}
.wrapper .container .or-wrapper .line {
  width: 2rem;
  border: none;
  height: 1px;
  margin: 0;
  background: rgba(0, 0, 0, 0.12);
}
.wrapper .container .or-wrapper .text {
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 10px;
}

/* wrapper no-account */
.wrapper .no-account {
  font-size: 14px;
  margin: 1.5rem 0;
}

.message__input {
  padding: 0.3rem;
}
.login-show-pswd__icon {
  cursor: pointer;
  font-size: 1.8rem;
  color: rgba(0, 0, 0, 0.384);
  padding: 0.5rem;
  margin: 0.3rem auto;
}
.green {
  color: rgba(3, 83, 3, 0.575);
}
.login-margin-right {
  margin-right: -2.7rem;
}
