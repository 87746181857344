.container {
  height: 90%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin: auto;
  transition: all 0.1s ease;
  width: 100%;
}
.header {
  height: 2.1rem;
  background: linear-gradient(to right, #334461, #252f3e);
  width: 80%;
  margin: 0 auto 1rem auto;
  justify-content: center;
  border-radius: 6px;
}
.container_fluid {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.container_fluid_upload {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-left: 3.6%;
}
.upload_div {
  display: flex;
  margin-left: 3.6%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.flex_start {
  justify-content: flex-start;
  margin: 0;
}
.input_wrapper {
  display: flex;
  width: 26%;
  padding: 0.4rem 0.5rem;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
.input_wrapper_double {
  display: flex;
  width: 28%;
  padding: 0.7rem 0.5rem;
  justify-content: space-around;
  margin: auto;
}
.input_upload {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: auto 0;
  width: 95%;
  height: 6rem;
  border: 0.5px solid rgba(190, 190, 190, 0.911);
  border-radius: 6px;
}
.input_field {
  height: 2.3rem;
  margin-bottom: 0.5rem;
  padding: 0 0.7rem;
  font-size: 16px;
  border: 0.5px solid rgba(190, 190, 190, 0.911);
  border-radius: 6px;
  color: rgb(17, 24, 39);
  transition: all 0.2s ease;
}
.input_field:focus {
  border: 1px solid #808080;
}
.input_status {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  padding: 0;
}
.input_status:first-of-type {
  margin-right: 1.5rem;
}
.label {
  padding: 0 0.7rem;
}
.options {
  background-color: white;
}
.text_center {
  color: azure;
  font-weight: 400;
}
.btn_wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
  padding-bottom: 3rem;
}
.btn {
  border: 0;
  background: #252f3e;
  color: #fff;
  font-weight: 600;
  min-width: 15rem;
  padding: 0.5rem;
  border-radius: 15px;
  font-size: 18px;
  letter-spacing: 0.5px;
  cursor: pointer;
  margin: 1rem auto;
}
.btn:hover {
  background: #252f3ec9;
}
.icon {
  font-size: 1.3rem;
  padding-right: 0.5rem;
  vertical-align: sub;
  color: #301b2e;
}
.break_line {
  width: 95%;
  margin: 0 auto 2rem auto;
}
.container h4 {
  margin-left: 2.5%;
}
.disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.26);
  background: rgba(0, 0, 0, 0.12);
}
.error_validation {
  color: red;
}
.succes_validation {
  color: green;
}
.input_field.succes {
  border-color: green;
}
.input_field.error {
  border-color: red;
}
.error_upload {
  margin-left: 3rem;
  /* font-family: sans-serif; */
  font-size: medium;
  color: red;
}
.input_wrapper .error_validation,
.succes_validation {
  font-size: 11px;
  margin-left: 1.5%;
  display: block;
  z-index: 5;
}
.upload_label.green {
  background-color: #27ae60;
}

.upload_wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 2.5rem;
  padding: 0.5rem;
  width: 28%;
}
.upload_container {
  display: flex;
}
.upload_file__name {
  max-height: 2rem;
  padding: 0.5rem 0.5rem;
  font-size: 14px;
  color: blueviolet;
}

.react-select__menu-list {
  z-index: 10;
}

option {
  border: 1px solid rgb(150, 148, 148);
  border-radius: 6px;
}

input[type="file"] {
  display: none;
}
textarea {
  resize: vertical;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
}
.upload_label {
  cursor: pointer;
  border: 0.5px solid rgba(190, 190, 190, 0.911);
  border-radius: 6px;
  overflow: hidden;
  width: fit-content;
  line-height: 120%;
  margin: 1rem 0;
  padding: 0.5rem;
}
.upload_label:hover {
  background: #afafaf;
}

@media only screen and (max-width: 1000px) {
  .container {
    width: 100%;
    overflow-y: scroll;
  }
  .input_date {
    width: 48%;
  }
  .input_wrapper {
    width: 35%;
  }
  .header {
    height: 2rem;
  }
  .text_center {
    font-size: 16px;
  }
}
@media only screen and (max-width: 770px) {
  .container {
    width: 90%;
    overflow-y: scroll;
  }
  .input_date {
    width: 100%;
  }
  .input_wrapper {
    width: 95%;
  }
  .header {
    height: 2rem;
  }
  .text_center {
    font-size: 16px;
  }
}
